// import React from "react";
// import mentalHealth from "../../../assets/Images/mentalHealth.png";
// import loveCard from "../../../assets/Images/loveCard.png";

// const CategoryCarousel = () => {
//   const data = [
//     { title: "love", image: mentalHealth },
//     { title: "health", image: loveCard },
//     { title: "tarot", image: mentalHealth },
//     { title: "love", image: loveCard },
//     { title: "health", image: mentalHealth },
//     { title: "tarot", image: loveCard },
//     { title: "love", image: mentalHealth },
//     { title: "health", image: loveCard },
//     { title: "tarot", image: mentalHealth },
//   ];

//   return (
//     <div style={styles.mainContainer}>
//       <div style={styles.carouselContainer}>
//         {data.map((item, index) => (
//           <div key={index} style={styles.cardStyles}>
//             <img
//               src={item.image}
//               alt={item.title}
//               style={{ width: "100%", height: "100%", objectFit: "contain" }}
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default CategoryCarousel;

// const styles = {
//   mainContainer: {
//     width: "100%",
//   },
//   carouselContainer: {
//     display: "flex",
//     flexDirection: "row",
//     gap: 40,
//     overflowX: "auto",
//     padding: "16px",
//     scrollbarWidth: "none", // Firefox
//     msOverflowStyle: "none", // Internet Explorer 10+
//     "&::-webkit-scrollbar": {
//       display: "none", // Safari and Chrome
//     },
//   },
//   cardStyles: {
//     width: "14rem",
//     height: "26rem",
//     flexShrink: 0,
//   },
// };

import React, { useEffect, useRef } from "react";
import mentalHealth from "../../../assets/Images/mentalHealth.png";
import loveCard from "../../../assets/Images/loveCard.png";

import lifeHealth from "../../../assets/Images/lifeHealth.png";
import marriageCard from "../../../assets/Images/marriageCard.png";
import bussinessCard from "../../../assets/Images/bussinessCard.png";
import careerCard from "../../../assets/Images/careerCard.png";

const CategoryCarousel = () => {
  const data = [
    { title: "love", image: mentalHealth },
    { title: "health", image: loveCard },
    { title: "tarot", image: lifeHealth },
    { title: "love", image: marriageCard },
    { title: "health", image: bussinessCard },
    { title: "tarot", image: careerCard },
  ];

  const carouselRef = useRef(null);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      const scrollWidth = carousel.scrollWidth;
      const animationDuration = scrollWidth / 50; // Adjust speed here

      carousel.style.setProperty("--scroll-width", `${scrollWidth}px`);
      carousel.style.setProperty(
        "--animation-duration",
        `${animationDuration}s`
      );
    }
  }, []);

  return (
    <div className="main-container">
      <div className="carousel-container" ref={carouselRef}>
        <div className="carousel-content">
          {data.map((item, index) => (
            <div key={index} className="card-styles">
              <img src={item.image} alt={item.title} className="card-image" />
            </div>
          ))}
        </div>
        <div className="carousel-content" aria-hidden="true">
          {data.map((item, index) => (
            <div
              key={`duplicate-${index}`}
              style={{ marginLeft: index === 0 ? 40 : 0 }}
              className="card-styles"
            >
              <img src={item.image} alt={item.title} className="card-image" />
            </div>
          ))}
        </div>
      </div>
      <style jsx>{`
        .main-container {
          width: 100%;
          overflow: hidden;
        }
        .carousel-container {
          display: flex;
          width: 100%;
        }
        .carousel-content {
          display: flex;
          gap: 40px;
          animation: scroll var(--animation-duration) linear infinite;
        }
        .card-styles {
          width: 14rem;
          height: 26rem;
          flex-shrink: 0;
        }
        .card-image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(calc(-1 * var(--scroll-width)));
          }
        }
      `}</style>
    </div>
  );
};

export default CategoryCarousel;
