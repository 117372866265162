import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const styles = {
  container: {
    width: "100%",
    background: "linear-gradient(to bottom, #FFA366, #FF8C42)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    padding: "2rem 1rem",
  },
  content: {
    maxWidth: "64rem",
    width: "100%",
  },
  title: {
    fontSize: "3rem",
    fontWeight: "800",
    textAlign: "center",
    color: "#FFF0E5",
    marginBottom: "2rem",
    lineHeight: "1.2",
  },
  card: {
    background: "rgba(255, 240, 229, 0.9)",
    borderRadius: "1rem",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "2rem",
    marginBottom: "2rem",
    transition: "all 0.3s ease",
  },
  heading: {
    fontSize: "1.875rem",
    fontWeight: "700",
    color: "#FF8C42",
    marginBottom: "1.5rem",
  },
  subheading: {
    fontSize: "1.5rem",
    fontWeight: "600",
    color: "#FF8C42",
    marginTop: "1.5rem",
    marginBottom: "1rem",
  },
  paragraph: {
    fontSize: "1.125rem",
    color: "#FF8C42",
    marginBottom: "1rem",
    lineHeight: "1.7",
  },
  list: {
    listStyleType: "disc",
    paddingLeft: "2rem",
    marginBottom: "1rem",
  },
  listItem: {
    fontSize: "1.125rem",
    color: "#FF8C42",
    marginBottom: "0.5rem",
  },
  contactInfo: {
    fontSize: "1.25rem",
    fontWeight: "600",
    color: "#FF8C42",
    textAlign: "center",
    marginTop: "1rem",
  },
};

const ContactUs = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <motion.h1
          style={styles.title}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6 }}
        >
          About MyGuruji
        </motion.h1>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <h2 style={styles.heading}>Our Platform</h2>
          <p style={styles.paragraph}>
            MyGuruji is a platform that connects users with skilled
            astrologers, providing personalized consultancy services. Our
            astrologers undergo a rigorous verification process to ensure the
            highest quality of service.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <h2 style={styles.heading}>How We Operate</h2>
          <p style={styles.paragraph}>
            To support our operations, MyGuruji retains a small fee from the
            consultancy charges. Users can easily add funds to their MyGuruji
            wallet through secure payment gateways like Razorpay and use their
            wallet balance to pay for consultations.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <h2 style={styles.heading}>Contact Us</h2>
          <p style={styles.paragraph}>We're here for you 24/7!</p>
          <h3 style={styles.subheading}>Get Help</h3>
          <p style={styles.paragraph}>
            If you have any questions or concerns, feel free to reach out to us:
          </p>
          <p style={styles.paragraph}>
            We're committed to assisting you and will respond as quickly as
            possible!
          </p>
          <p style={styles.contactInfo}>Email: product@MyGuruji.life</p>

          <p style={styles.contactInfo}>
            Address: 12-130 Nithyananda Nilaya, Kadekar Upudi, Karnataka 574118
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default ContactUs;
