// colors.js
const Colors = {
  primaryBg: "#FFA366",
  secondary: "#2ecc71",
  danger: "#e74c3c",
  warning: "#f1c40f",
  success: "#2ecc71",
  background: "#f5f5f5",
  textPrimary: "#333",
};

export default Colors;
