// import React, { useEffect, useState } from "react";
// import { motion } from "framer-motion";
// import Colors from "../../../assets/ColorFile/Colors";
// import homepageBgImage from "../../../assets/Images/landingBgImage.png";

// const TopSection = () => {
//   const [isVisible, setIsVisible] = useState(false);

//   useEffect(() => {
//     setIsVisible(true);
//   }, []);

//   return (
//     <>
//       <div style={{ position: "absolute", top: 0, left: 0 }}>
//         <img src={homepageBgImage} style={{ width: 500, height: 500 }} />
//       </div>
//       <motion.h3
//         style={styles.mainHeading}
//         initial={{ opacity: 0, y: 20 }}
//         animate={isVisible ? { opacity: 1, y: 0 } : {}}
//         transition={{ duration: 0.8, delay: 0.2 }}
//       >
//         Journey to Your Soul's True Purpose <br /> Through Astrology
//       </motion.h3>
//       <motion.h5
//         style={styles.mainPara}
//         initial={{ opacity: 0, y: 20 }}
//         animate={isVisible ? { opacity: 1, y: 0 } : {}}
//         transition={{ duration: 0.8, delay: 0.4 }}
//       >
//         Discover the profound wisdom of the stars and unlock the secrets of your
//         soul's journey. Our astrology insights <br /> guide you to a deeper
//         connection with the universe, helping you find peace, purpose, and
//         spiritual clarity.
//       </motion.h5>

//       <motion.button
//         style={styles.btnStyles}
//         initial={{ opacity: 0, y: 20 }}
//         animate={isVisible ? { opacity: 1, y: 0 } : {}}
//         transition={{ duration: 0.8, delay: 0.6 }}
//       >
//         Join Our Community
//       </motion.button>
//     </>
//   );
// };

// export default TopSection;

// const styles = {
//   mainHeading: {
//     fontSize: "2.75rem",
//     fontFamily: "Quicksand",
//     color: "#FFF0E5",
//     textAlign: "center",
//     paddingTop: 50,
//     lineHeight: 1.25,
//   },
//   mainPara: {
//     fontSize: "1rem",
//     fontFamily: "Segoe UI",
//     color: "#FFF0E5",
//     textAlign: "center",
//     paddingTop: 16,
//     fontWeight: "400",
//   },
//   btnStyles: {
//     fontSize: ".85rem",
//     color: "#FFA366",
//     background: "#FFF0E5",
//     marginTop: 60,
//     padding: "16px 60px",
//     border: "none",
//     borderRadius: 40,
//     fontWeight: "600",
//     cursor: "pointer",
//   },
// };

// TopSection.js
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import homepageBgImage from "../../../assets/Images/landingBgImage.png";
import "./StylesComponents.css";

const TopSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <>
      <div className="image-container">
        <img
          src={homepageBgImage}
          className="rotating-image"
          alt="Background"
        />
      </div>
      <motion.h3
        style={styles.mainHeading}
        initial={{ opacity: 0, y: 20 }}
        animate={isVisible ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        Journey to Your Soul's True Purpose <br /> Through Astrology
      </motion.h3>
      <motion.h5
        style={styles.mainPara}
        initial={{ opacity: 0, y: 20 }}
        animate={isVisible ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        Discover the profound wisdom of the stars and unlock the secrets of your
        soul's journey. Our astrology insights <br /> guide you to a deeper
        connection with the universe, helping you find peace, purpose, and
        spiritual clarity.
      </motion.h5>
      <motion.button
        style={styles.btnStyles}
        initial={{ opacity: 0, y: 20 }}
        animate={isVisible ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        Join Our Community
      </motion.button>
    </>
  );
};

const styles = {
  mainHeading: {
    fontSize: "2.75rem",
    fontFamily: "Quicksand",
    color: "#FFF0E5",
    textAlign: "center",
    paddingTop: 50,
    lineHeight: 1.25,
  },
  mainPara: {
    fontSize: "1rem",
    fontFamily: "Segoe UI",
    color: "#FFF0E5",
    textAlign: "center",
    paddingTop: 16,
    fontWeight: "400",
  },
  btnStyles: {
    fontSize: ".85rem",
    color: "#FFA366",
    background: "#FFF0E5",
    marginTop: 60,
    padding: "16px 60px",
    border: "none",
    borderRadius: 40,
    fontWeight: "600",
    cursor: "pointer",
  },
};

export default TopSection;
