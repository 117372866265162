import React from "react";

import Colors from "../../assets/ColorFile/Colors";
import homepageBgImage from "../../assets/Images/landingBgImage.png";
import TopSection from "./Components/TopSection";
import CategoryCarousel from "./Components/CategoryCarousel";
import SubHeader from "./Components/SubHeader";
import Discover from "./Components/Discover";
import MobileSection from "./Components/MobileSection";
import OutlineText from "./Components/OutlineText";
import Footer from "./Components/Footer";

const HomePage = () => {
  return (
    <div
      style={{
        width: "100%",
        background: Colors.primaryBg,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <TopSection />
      <SubHeader text={"With you in Every Joy and Struggle Time"} />
      <CategoryCarousel />
      <SubHeader text={"Discover what the Future Holds for You"} />
      <Discover />
      <SubHeader text={"Discover what the Future Holds for You"} />
      <MobileSection />
      <OutlineText />
      <Footer />
    </div>
  );
};

export default HomePage;

const styles = {
  mainHeading: {
    fontSize: "2.75rem",
    fontFamily: "Quicksand",
    color: "#FFF0E5",
    textAlign: "center",
    paddingTop: 50,
    lineHeight: 1.25,
  },
  mainPara: {
    fontSize: "1rem",
    fontFamily: "Segoe UI",
    color: "#FFF0E5",
    textAlign: "center",
    paddingTop: 16,
    fontWeight: "400",
  },
  btnStyles: {
    fontSize: ".85rem",
    color: "#FFA366",
    background: "#FFF0E5",
    marginTop: 60,
    padding: "16px 60px",
    border: "none",
    borderRadius: 40,
    fontWeight: "600",
  },
};
