import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const styles = {
  container: {
    width: "100%",
    background: "linear-gradient(to bottom, #FFA366 ,  #FF8C42  ",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    padding: "2rem 1rem",
  },
  content: {
    maxWidth: "64rem",
    width: "100%",
  },
  title: {
    fontSize: "3rem",
    fontWeight: "800",
    textAlign: "center",
    color: "#FFF0E5",
    marginBottom: "3rem",
    lineHeight: "1.2",
  },
  titleSpan: {
    color: "#FFF0E5",
  },
  card: {
    background: "rgba(255, 240, 229, 0.9)",
    borderRadius: "1rem",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "2rem",
    marginBottom: "2rem",
    transition: "all 0.3s ease",
  },
  heading: {
    fontSize: "1.875rem",
    fontWeight: "700",
    color: "#FF8C42",
    marginBottom: "1.5rem",
  },
  paragraph: {
    fontSize: "1.125rem",
    color: "#FF8C42",
    marginBottom: "1.5rem",
    lineHeight: "1.7",
  },
  serviceGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    gap: "1rem",
  },
  serviceItem: {
    display: "flex",
    alignItems: "center",
    background: "#FFD9C0",
    padding: "0.75rem",
    borderRadius: "0.5rem",
  },
  serviceDot: {
    height: "0.5rem",
    width: "0.5rem",
    backgroundColor: "#FF8C42",
    borderRadius: "50%",
    marginRight: "0.5rem",
  },
  serviceText: {
    color: "#FF8C42",
  },
  achievementBox: {
    background: "#FFA366",
    color: "#FFF0E5",
    textAlign: "center",
    padding: "1rem",
    borderRadius: "0.5rem",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  achievementNumber: {
    fontSize: "1.875rem",
    fontWeight: "700",
  },
  achievementText: {
    fontSize: "1.25rem",
  },
};

const AboutUs = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <motion.h1
          style={styles.title}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6 }}
        >
          Welcome to <span style={styles.titleSpan}>MyGuruji</span>
        </motion.h1>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <p style={styles.paragraph}>
            Your ultimate destination for astrology services that empower and
            enlighten your life journey.
          </p>
          <p style={styles.paragraph}>
            At MyGuruji, we connect you with expert astrologers skilled in
            various practices, including Vedic Astrology, Tarot Reading, Online
            Horoscope Matching, Palm Reading, Numerology, and much more. We
            believe in harnessing the ancient wisdom of astrology to guide you
            through life's challenges and help you discover your true purpose.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <h2 style={styles.heading}>What We Do</h2>
          <p style={styles.paragraph}>
            MyGuruji offers a comprehensive range of astrology services
            tailored to meet your needs. Our platform provides valuable insights
            into astrology, horoscopes, and related topics to educate and
            inspire our visitors.
          </p>
          <p style={styles.paragraph}>
            Whether you're seeking guidance on love, career, health, or personal
            growth, our team of expert astrologers is here to offer personalized
            readings and consultations. From horoscope interpretations to travel
            readings, we are dedicated to helping you unlock the mysteries of
            the universe and find clarity in your decisions.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <h2 style={styles.heading}>Our Services</h2>
          <div style={styles.serviceGrid}>
            {[
              "Vedic Astrology",
              "Tarot Reading",
              "Horoscope Matching",
              "Palm Reading",
              "Numerology",
              "Personal Growth",
            ].map((service, index) => (
              <motion.div
                key={index}
                style={styles.serviceItem}
                initial={{ opacity: 0, x: -20 }}
                animate={isVisible ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.6, delay: 0.8 + index * 0.1 }}
              >
                <span style={styles.serviceDot}></span>
                <span style={styles.serviceText}>{service}</span>
              </motion.div>
            ))}
          </div>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.8 }}
        >
          <h2 style={styles.heading}>Achievements</h2>
          <p style={styles.paragraph}>
            MyGuruji proudly features over 100 skilled astrologers, reflecting
            our commitment to delivering valuable services and insights in the
            realm of astrology and horoscope readings.
          </p>
          <p style={styles.paragraph}>
            This diverse and knowledgeable team allows us to offer a wide array
            of guidance across different aspects of life. Our achievements
            underscore our dedication to excellence and our mission to provide
            you with the highest quality of service and information available.
          </p>
          <motion.div
            style={styles.achievementBox}
            initial={{ scale: 0.9, opacity: 0 }}
            animate={isVisible ? { scale: 1, opacity: 1 } : {}}
            transition={{ duration: 0.6, delay: 1 }}
          >
            <span style={styles.achievementNumber}>100+</span>
            <p style={styles.achievementText}>Expert Astrologers</p>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutUs;
