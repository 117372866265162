// import React from "react";
// import firstDis from "../../../assets/Images/firstDis.png";
// import secondDis from "../../../assets/Images/secondDis.png";
// import thirdDis from "../../../assets/Images/thirdDis.png";
// import fourthDis from "../../../assets/Images/fourthDis.png";

// const Discover = () => {
//   return (
//     <div style={styles.mainContainer}>
//       <div style={styles.content}>
//         <div style={styles.imageContainer}>
//           <img src={firstDis} alt="Discover" style={styles.image} />
//         </div>
//         <div style={{ ...styles.imageContainer, justifyContent: "flex-end" }}>
//           <img src={secondDis} alt="Discover" style={styles.image} />
//         </div>
//         <div style={styles.imageContainer}>
//           <img src={thirdDis} alt="Discover" style={styles.image} />
//         </div>
//         <div style={{ ...styles.imageContainer, justifyContent: "flex-end" }}>
//           <img src={fourthDis} alt="Discover" style={styles.image} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Discover;

// const styles = {
//   mainContainer: {
//     width: "100%",
//     display: "flex",
//     justifyContent: "center",
//   },
//   content: {
//     display: "flex",
//     flexDirection: "column",
//     width: "80%",
//   },
//   imageContainer: {
//     display: "flex",
//     justifyContent: "flex-start",
//     width: "100%",
//     marginTop: 40,
//   },
//   image: {
//     width: "auto",
//     height: "12rem",
//     objectFit: "contain",
//   },
// };

import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import firstDis from "../../../assets/Images/firstDis.png";
import secondDis from "../../../assets/Images/secondDis.png";
import thirdDis from "../../../assets/Images/thirdDis.png";
import fourthDis from "../../../assets/Images/fourthDis.png";

const Discover = () => {
  const [isVisible, setIsVisible] = useState([false, false, false, false]);
  const refs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    const observers = refs.map((ref, index) => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible((prev) => {
              const newState = [...prev];
              newState[index] = true;
              return newState;
            });
            observer.unobserve(ref.current);
          }
        },
        { threshold: 0.1 }
      );

      if (ref.current) {
        observer.observe(ref.current);
      }

      return observer;
    });

    return () => {
      observers.forEach((observer, index) => {
        if (refs[index].current) {
          observer.unobserve(refs[index].current);
        }
      });
    };
  }, []);

  const images = [firstDis, secondDis, thirdDis, fourthDis];

  return (
    <div style={styles.mainContainer}>
      <div style={styles.content}>
        {images.map((img, index) => (
          <div
            key={index}
            style={{
              ...styles.imageContainer,
              justifyContent: index % 2 === 0 ? "flex-start" : "flex-end",
            }}
            ref={refs[index]}
          >
            <motion.img
              src={img}
              alt={`Discover ${index + 1}`}
              style={styles.image}
              initial={{ opacity: 0, y: 50 }}
              animate={isVisible[index] ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, ease: "easeOut" }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Discover;

const styles = {
  mainContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
  },
  imageContainer: {
    display: "flex",
    width: "100%",
    marginTop: 40,
  },
  image: {
    width: "auto",
    height: "12rem",
    objectFit: "contain",
  },
};
