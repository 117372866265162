import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Footer from "../HomePage/Components/Footer";

const styles = {
  container: {
    width: "100%",
    background: "linear-gradient(to bottom, #FFA366, #FF8C42)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    padding: "2rem 1rem",
  },
  content: {
    maxWidth: "64rem",
    width: "100%",
  },
  title: {
    fontSize: "3rem",
    fontWeight: "800",
    textAlign: "center",
    color: "#FFF0E5",
    marginBottom: "2rem",
    lineHeight: "1.2",
  },
  card: {
    background: "rgba(255, 240, 229, 0.9)",
    borderRadius: "1rem",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "2rem",
    marginBottom: "2rem",
    transition: "all 0.3s ease",
  },
  heading: {
    fontSize: "1.875rem",
    fontWeight: "700",
    color: "#FF8C42",
    marginBottom: "1.5rem",
  },
  subheading: {
    fontSize: "1.5rem",
    fontWeight: "600",
    color: "#FF8C42",
    marginTop: "1.5rem",
    marginBottom: "1rem",
  },
  paragraph: {
    fontSize: "1.125rem",
    color: "#FF8C42",
    marginBottom: "1rem",
    lineHeight: "1.7",
  },
  list: {
    listStyleType: "disc",
    paddingLeft: "2rem",
    marginBottom: "1rem",
  },
  listItem: {
    fontSize: "1.125rem",
    color: "#FF8C42",
    marginBottom: "0.5rem",
  },
};

const TermsConditions = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <motion.h1
          style={styles.title}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6 }}
        >
          Terms & Conditions
        </motion.h1>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <h2 style={styles.heading}>IMPORTANT DISCLAIMER:</h2>
          <p style={styles.paragraph}>
            Please read this carefully. The services provided are at your own
            risk and are intended solely for obtaining astrological
            consultations. Our astrology partners may use methods such as Vedic
            Astrology, Tarot Reading, Numerology, and others. You acknowledge
            that astrological consultations are subjective, and results may vary
            between different astrologers. The Company and its partners disclaim
            all warranties regarding the accuracy of any advice or predictions
            provided. These services are not a substitute for professional
            medical, legal, or other advice.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <h3 style={styles.subheading}>I. General</h3>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              These Terms govern your use of MyGuruji's services, including
              the mobile app and website.
            </li>
            <li style={styles.listItem}>
              By accessing these services, you agree to these Terms, which
              create a contractual relationship.
            </li>
            <li style={styles.listItem}>
              The Company may terminate your access to the services at any time
              without notice.
            </li>
            <li style={styles.listItem}>
              The Company reserves the right to amend these Terms periodically.
            </li>
          </ul>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <h3 style={styles.subheading}>II. Services</h3>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              The Services offer a platform for users to connect with
              independent astrologers.
            </li>
            <li style={styles.listItem}>
              Services are intended for personal, non-commercial use.
            </li>
            <li style={styles.listItem}>
              You may not reproduce, modify, or distribute any part of the
              Services without explicit permission.
            </li>
            <li style={styles.listItem}>
              To use most features, you must create an account and be at least
              18 years old.
            </li>
          </ul>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.8 }}
        >
          <h3 style={styles.subheading}>III. Payment</h3>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              Some services require purchasing in-app credits ("Credits").
            </li>
            <li style={styles.listItem}>
              Credits can be used for consultations, with costs deducted per
              minute based on the astrologer's rates.
            </li>
            <li style={styles.listItem}>
              All transactions are final. If dissatisfied, you may contact the
              Company within seven days of the event.
            </li>
          </ul>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 1 }}
        >
          <h3 style={styles.subheading}>IV. Warranties and Disclaimers</h3>
          <p style={styles.paragraph}>
            The Services are provided "as is" without warranties of any kind.
            The Company does not guarantee the accuracy or reliability of any
            predictions.
          </p>
          <p style={styles.paragraph}>
            The Company is not liable for indirect or consequential damages
            related to your use of the Services.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 1.2 }}
        >
          <h3 style={styles.subheading}>V. Data Collection and Privacy</h3>
          <p style={styles.paragraph}>
            The Company may collect and process your personal data. For details
            on data practices, please refer to our Privacy Policy.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 1.4 }}
        >
          <h3 style={styles.subheading}>VI. Indemnity</h3>
          <p style={styles.paragraph}>
            You agree to indemnify the Company against any claims arising from
            your use of the Services or violation of these Terms.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 1.6 }}
        >
          <h3 style={styles.subheading}>VII. Jurisdiction</h3>
          <p style={styles.paragraph}>
            These Terms are governed by the laws of India. Disputes will be
            resolved through arbitration in New Delhi.
          </p>
          <p style={styles.paragraph}>
            The information provided is for entertainment purposes only.
          </p>
          <p style={styles.paragraph}>
            By using our services, you acknowledge that you have read and
            understood these Terms & Conditions.
          </p>
        </motion.div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsConditions;
