import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import leftLeaf from "../../../assets/Images/leafLeft.png";
import rightLeaf from "../../../assets/Images/leafRight.png";

const SubHeader = ({ text }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div style={styles.mainContainer}>
      <div style={styles.topSec}>
        <img src={leftLeaf} style={{ width: 120, height: 120 }} />
        <motion.h4
          style={styles.headerColor}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={isVisible ? { opacity: 1, scale: 1 } : {}}
          transition={{ duration: 2, ease: "easeOut" }}
        >
          {text}
        </motion.h4>
        <img src={rightLeaf} style={{ width: 120, height: 120 }} />
      </div>
    </div>
  );
};

export default SubHeader;

const styles = {
  mainContainer: {
    marginTop: 70,
  },
  topSec: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 20,
  },
  headerColor: {
    color: "#FFF0E5",
    fontSize: "2.2rem",
    fontFamily: "sans-serif",
    letterSpacing: 1,
  },
};
