import React from "react";

const Services = () => {
  return (
    <div style={{ width: "100vw", height: "100vh", background: "blue" }}>
      <h1>Services</h1>
    </div>
  );
};

export default Services;
