// import React, { useEffect, useRef, useState } from "react";
// import { motion } from "framer-motion";
// import mobileImg from "../../../assets/Images/deviceImg.png";
// import bgImg from "../../../assets/Images/bgImg.png";
// import firstImgs from "../../../assets/Images/firstimgs.png";
// import secondImgs from "../../../assets/Images/secondImgs.png";
// import thirdImgs from "../../../assets/Images/thirdImgs.png";
// import fourthImgs from "../../../assets/Images/fourthImgs.png";

// const MobileSection = () => {
//   const [isVisible, setIsVisible] = useState(false);
//   const ref = useRef(null);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         if (entry.isIntersecting) {
//           setIsVisible(true);
//           observer.unobserve(ref.current);
//         }
//       },
//       { threshold: 0.1 }
//     );

//     if (ref.current) {
//       observer.observe(ref.current);
//     }

//     return () => {
//       if (ref.current) {
//         observer.unobserve(ref.current);
//       }
//     };
//   }, []);

//   return (
//     <div style={styles.mainContainer}>
//       <div style={styles.baseMobile} ref={ref}>
//         <div style={{ ...styles.msgContainer, left: -235, top: -100 }}>
//           <h6 style={styles.msgText}>
//             MyGuruji offers clarity in life's challenges.
//           </h6>
//           <img src={firstImgs} style={{ width: 42, height: 42 }} />
//         </div>
//         <div
//           style={{
//             ...styles.msgContainer,
//             right: -230,
//             top: -160,
//             paddingLeft: 10,
//           }}
//         >
//           <img src={secondImgs} style={{ width: 42, height: 42 }} />
//           <h6 style={styles.msgText}>
//             Enhance spiritual awareness and intuition{" "}
//           </h6>
//         </div>
//         <div style={{ ...styles.msgContainer, left: -240, top: 100 }}>
//           <h6 style={styles.msgText}>
//             MyGuruji brings insight and emotional balance{" "}
//           </h6>
//           <img src={firstImgs} style={{ width: 42, height: 42 }} />
//         </div>
//         <div
//           style={{
//             ...styles.msgContainer,
//             right: -230,
//             top: 30,
//             paddingLeft: 10,
//           }}
//         >
//           <img src={firstImgs} style={{ width: 42, height: 42 }} />
//           <h6 style={styles.msgText}>
//             Helps align your life with cosmic energies{" "}
//           </h6>
//         </div>
//         <motion.img
//           src={mobileImg}
//           style={{
//             width: 325,
//             height: 500,
//             position: "absolute",
//             bottom: 60,
//           }}
//           initial={{ opacity: 0, y: 50 }}
//           animate={isVisible ? { opacity: 1, y: 0 } : {}}
//           transition={{ duration: 0.5, ease: "easeOut" }}
//         />
//       </div>
//     </div>
//   );
// };

// export default MobileSection;

// const styles = {
//   mainContainer: {
//     display: "flex",
//     justifyContent: "center",
//     width: "100%",
//   },
//   baseMobile: {
//     width: "408px",
//     height: "392px",
//     background: "linear-gradient(to bottom, #E8A278, #FF8C42)",
//     marginTop: "200px",
//     borderRadius: "40px",
//     position: "relative",
//     display: "flex",
//     justifyContent: "center",
//     backgroundImage: `url(${bgImg})`,
//     backgroundSize: "cover",
//     backgroundPosition: "center",
//     boxShadow: "0 0 0.5px 0.5px rgba(255, 255, 255, 0.2)",
//   },
//   msgContainer: {
//     position: "absolute",
//     display: "flex",
//     alignItems: "center",
//     background: "#FFF0E5",
//     padding: "6px 10px 6px 16px",
//     gap: 16,
//     borderRadius: 14,
//     zIndex: 99999,
//   },
//   msgText: {
//     fontSize: 13,
//     fontFamily: "Segoe UI",
//     color: "#000",
//     fontWeight: "400",
//   },
// };

import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import mobileImg from "../../../assets/Images/deviceImg.png";
import bgImg from "../../../assets/Images/bgImg.png";
import firstImgs from "../../../assets/Images/firstimgs.png";
import secondImgs from "../../../assets/Images/secondImgs.png";

const MobileSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(ref.current);
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const staggeredAnimationLeft = {
    hidden: { opacity: 0, x: 50 }, // Start from the mobile (right)
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.3, // Stagger the appearance of each container
        duration: 0.5,
        ease: "easeOut",
      },
    }),
  };

  const staggeredAnimationRight = {
    hidden: { opacity: 0, x: -50 }, // Start from the mobile (left)
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.3, // Stagger the appearance of each container
        duration: 0.5,
        ease: "easeOut",
      },
    }),
  };

  return (
    <div style={styles.mainContainer}>
      <div style={styles.baseMobile} ref={ref}>
        {/* Left-side message container */}
        <motion.div
          custom={0}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={staggeredAnimationLeft}
          style={{ ...styles.msgContainer, left: -235, top: -100 }}
        >
          <h6 style={styles.msgText}>
            MyGuruji offers clarity in life's challenges.
          </h6>
          <img src={firstImgs} style={{ width: 42, height: 42 }} />
        </motion.div>

        {/* Right-side message container */}
        <motion.div
          custom={1}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={staggeredAnimationRight}
          style={{
            ...styles.msgContainer,
            right: -210,
            top: -160,
            paddingLeft: 10,
          }}
        >
          <img src={secondImgs} style={{ width: 42, height: 42 }} />
          <h6 style={styles.msgText}>
            Enhance spiritual awareness and intuition{" "}
          </h6>
        </motion.div>

        {/* Left-side message container */}
        <motion.div
          custom={2}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={staggeredAnimationLeft}
          style={{ ...styles.msgContainer, left: -280, top: 100 }}
        >
          <h6 style={styles.msgText}>
            MyGuruji brings insight and emotional balance{" "}
          </h6>
          <img src={firstImgs} style={{ width: 42, height: 42 }} />
        </motion.div>

        {/* Right-side message container */}
        <motion.div
          custom={3}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={staggeredAnimationRight}
          style={{
            ...styles.msgContainer,
            right: -210,
            top: 30,
            paddingLeft: 10,
          }}
        >
          <img src={firstImgs} style={{ width: 42, height: 42 }} />
          <h6 style={styles.msgText}>
            Helps align your life with cosmic energies{" "}
          </h6>
        </motion.div>

        {/* Mobile image */}
        <motion.img
          src={mobileImg}
          style={{
            width: 325,
            height: 500,
            position: "absolute",
            bottom: 60,
          }}
          initial={{ opacity: 0, y: 50 }}
          animate={isVisible ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5, ease: "easeOut" }}
        />
      </div>
    </div>
  );
};

export default MobileSection;

const styles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  baseMobile: {
    width: "408px",
    height: "392px",
    background: "linear-gradient(to bottom, #E8A278, #FF8C42)",
    marginTop: "200px",
    borderRadius: "40px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    backgroundImage: `url(${bgImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    boxShadow: "0 0 0.5px 0.5px rgba(255, 255, 255, 0.2)",
  },
  msgContainer: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    background: "#FFF0E5",
    padding: "6px 10px 6px 16px",
    gap: 16,
    borderRadius: 14,
    zIndex: 99999,
  },
  msgText: {
    fontSize: 13,
    fontFamily: "Segoe UI",
    color: "#000",
    fontWeight: "400",
  },
};
