import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
import HomePage from "./components/HomePage/HomePage";
import AboutUs from "./components/AboutUs/AboutUs";
import Services from "./components/ServicePage/Services";
import HeaderSection from "./components/HomepageComponents/HeaderSection";
import TermsConditions from "./components/TermsCondition/TermsCondition";
import PrivacyPolicy from "./components/Privacy/PrivacyPolicy";
import ContactUs from "./components/Contact/Contact";
import RefundPolicy from "./components/Refund/Refund";

// Custom hook for scrolling to top
const useScrollToTop = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

// ScrollToTop component
const ScrollToTop = ({ children }) => {
  useScrollToTop();
  return children;
};

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <HeaderSection />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/termscondition" element={<TermsConditions />} />
          <Route path="/policy" element={<PrivacyPolicy />} />
          <Route path="/refund" element={<RefundPolicy />} />
          <Route path="/contact" element={<ContactUs />} />
          {/* You can add a catch-all route for 404 here if needed */}
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default App;
