import React from "react";
import { Link, useLocation } from "react-router-dom";

const HeaderSection = () => {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? styles.activeMenuItem : styles.menuItem;
  };

  return (
    <div style={styles.mainContainer}>
      <div style={styles.headerSec}>
        <h3 style={styles.MyGurujiText}>MyGuruji</h3>

        <div style={styles.menuRightSec}>
          <ul style={styles.menuSubSec}>
            <li>
              <Link to="/" style={isActive("/")}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" style={isActive("/about")}>
                About
              </Link>
            </li>
            <li>
              <Link to="/contact" style={isActive("/contact")}>
                Contact Us
              </Link>
            </li>
            {/* <li
              style={{
                ...styles.menuItem,
                border: "1px solid #FFF0E5",
                padding: "5px 24px 8px 24px",
                borderRadius: 40,
              }}
            >
              Join Us
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;

const styles = {
  mainContainer: {
    width: "100%",
    background: "#FFA366",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: 0,
    boxSizing: "border-box",
  },
  headerSec: {
    width: "80%",
    alignSelf: "center",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "rgba(255, 255, 255, 0.1)",
    // border: "1px solid white",
    padding: "12px 30px",
    borderRadius: 42,
    marginTop: 80,
    marginBottom: 20,
    boxShadow: "0 0 1px 1px rgba(255, 255, 255, 0.7)",
  },
  menuRightSec: {
    flex: 1,
  },
  menuSubSec: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    // background: "yellow",
    listStyleType: "none",
    padding: 0,
    margin: 0,
    gap: 40,
    paddingRight: 0,
  },
  menuItem: {
    // marginLeft: "20px",
    cursor: "pointer",
    textDecoration: "none",
    color: "black",
    fontSize: "1rem",
    color: "#FFF0E5",
    fontFamily: "Segoe UI",
  },
  activeMenuItem: {
    marginLeft: "20px",
    cursor: "pointer",
    textDecoration: "none",
    color: "white",
    fontFamily: "Segoe UI",
    fontWeight: "700",
  },
  MyGurujiText: {
    color: "#FFF0E5",
    fontWeight: "400",
    fontSize: "1.25rem",
    fontFamily: "PT serif",
    letterSpacing: 1,
  },
};
