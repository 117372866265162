import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Footer from "../HomePage/Components/Footer";

const styles = {
  container: {
    width: "100%",
    background: "linear-gradient(to bottom, #FFA366, #FF8C42)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    padding: "2rem 1rem",
  },
  content: {
    maxWidth: "64rem",
    width: "100%",
  },
  title: {
    fontSize: "3rem",
    fontWeight: "800",
    textAlign: "center",
    color: "#FFF0E5",
    marginBottom: "2rem",
    lineHeight: "1.2",
  },
  card: {
    background: "rgba(255, 240, 229, 0.9)",
    borderRadius: "1rem",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "2rem",
    marginBottom: "2rem",
    transition: "all 0.3s ease",
  },
  heading: {
    fontSize: "1.875rem",
    fontWeight: "700",
    color: "#FF8C42",
    marginBottom: "1.5rem",
  },
  subheading: {
    fontSize: "1.5rem",
    fontWeight: "600",
    color: "#FF8C42",
    marginTop: "1.5rem",
    marginBottom: "1rem",
  },
  paragraph: {
    fontSize: "1.125rem",
    color: "#FF8C42",
    marginBottom: "1rem",
    lineHeight: "1.7",
  },
  list: {
    listStyleType: "disc",
    paddingLeft: "2rem",
    marginBottom: "1rem",
  },
  listItem: {
    fontSize: "1.125rem",
    color: "#FF8C42",
    marginBottom: "0.5rem",
  },
};

const PrivacyPolicy = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <motion.h1
          style={styles.title}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6 }}
        >
          Privacy Policy for MyGuruji
        </motion.h1>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <h2 style={styles.heading}>Introduction</h2>
          <p style={styles.paragraph}>
            Welcome to www.MyGuruji.life, operated by Vishal Vijay Suvarna.
            This privacy policy outlines how we handle your personal information
            and data while using the MyGuruji platform. We are committed to
            safeguarding your privacy and managing your data responsibly.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <h2 style={styles.heading}>Entity Responsible for Data Processing</h2>
          <p style={styles.paragraph}>
            This policy applies to data processed by Vishal Vijay Suvarna ,
            based in Mumbai. For any privacy-related concerns or inquiries,
            please contact us at product@MyGuruji.life.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <h2 style={styles.heading}>Information We Collect</h2>
          <h3 style={styles.subheading}>
            I. Information You Provide Directly:
          </h3>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              <strong>Account Setup and Profile Management:</strong> When you
              create an account or update your profile, you may provide personal
              information such as your name, email address, and other contact
              details.
            </li>
          </ul>
          <h3 style={styles.subheading}>
            II. Information Collected Automatically:
          </h3>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              <strong>Internet Access:</strong> We collect data from your
              interactions with our service to enable core functionalities of
              the app.
            </li>
            <li style={styles.listItem}>
              <strong>Device Information:</strong> We gather details about your
              device, including hardware model, operating system version, unique
              device identifiers, and mobile network information to enhance our
              app's performance.
            </li>
          </ul>
          <h3 style={styles.subheading}>III. Permissions-Based Information:</h3>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              <strong>Camera:</strong> We access your camera only with your
              consent to allow you to capture photos or videos directly from the
              app.
            </li>
            <li style={styles.listItem}>
              <strong>Microphone:</strong> With your permission, we utilize your
              microphone for features requiring audio input.
            </li>
            <li style={styles.listItem}>
              <strong>Location:</strong> We collect location data only after
              obtaining your consent to offer location-based services.
            </li>
          </ul>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.8 }}
        >
          <h2 style={styles.heading}>How We Use Your Information</h2>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              To provide, maintain, and improve our services.
            </li>
            <li style={styles.listItem}>To personalize your experience.</li>
            <li style={styles.listItem}>
              To communicate with you about products, services, offers, and
              events that may interest you.
            </li>
            <li style={styles.listItem}>
              To monitor and analyze trends and usage related to our services.
            </li>
            <li style={styles.listItem}>
              To detect, investigate, and prevent fraudulent transactions and
              other illegal activities.
            </li>
          </ul>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 1 }}
        >
          <h2 style={styles.heading}>Sharing of Information</h2>
          <p style={styles.paragraph}>
            We retain personal information only as long as necessary to provide
            services and fulfill your requests or comply with legal obligations.
            Upon request, we will delete or anonymize your personal information
            unless legally required to maintain it for specific reasons.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 1.2 }}
        >
          <h2 style={styles.heading}>Your Data Protection Rights</h2>
          <p style={styles.paragraph}>
            You have rights regarding your personal data, including the right to
            access, correct, or delete information we hold about you. You may
            also request that we restrict certain processing of your data.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 1.4 }}
        >
          <h2 style={styles.heading}>Data Security</h2>
          <p style={styles.paragraph}>
            We implement various security measures to protect your personal
            information both online and offline, tailored to the sensitivity of
            the information we collect.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 1.6 }}
        >
          <h2 style={styles.heading}>Changes to This Policy</h2>
          <p style={styles.paragraph}>
            We may update this privacy policy in response to legal, technical,
            or business developments. When changes occur, we will inform you
            appropriately.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 1.8 }}
        >
          <h2 style={styles.heading}>Contact Information</h2>
          <p style={styles.paragraph}>
            For any questions about this policy or our data practices, please
            reach out to us at product@MyGuruji.life.
          </p>
          <p style={styles.paragraph}>
            <strong>Developer Account Name:</strong> Vishal Vijay Suvarna
            <br />
            <strong>Operated by:</strong> Vishal Vijay Suvarna .
          </p>
        </motion.div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
