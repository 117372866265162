import React from "react";
import { Link, useNavigate } from "react-router-dom";

const styles = {
  footer: {
    backgroundColor: "#f8f3ee",
    color: "#3d1d0a",
    padding: "52px 0 32px 0",
    width: "100%",
  },
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
  },
  flexWrap: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  section: {
    marginBottom: "24px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "8px",
    fontFamily: "sans-serif",
  },
  paragraph: {
    fontSize: "13px",
  },
  companyList: {
    fontWeight: "600",
    marginBottom: "8px",
  },
  hotLabel: {
    marginLeft: "8px",
    backgroundColor: "black",
    color: "white",
    fontSize: "10px",
    padding: "1px 8px",
    borderRadius: "12px",
    marginTop: 3,
  },
  subscribeInput: {
    borderBottom: "1px solid #3d1d0a",
    backgroundColor: "transparent",
    // padding: "0px 20px 4px 4px",
    marginRight: "8px",
    flexGrow: 1,
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    fontSize: "12px",
    paddingBottom: "6px",
    outline: "none", // Removes the default focus outline
    "&:focus": {
      border: "none",
      borderBottom: "1px solid #3d1d0a",
    },
  },
  subscribeButton: {
    // backgroundColor: "#3d1d0a",
    color: "white",
    padding: "4px",
  },
  bottomSection: {
    marginTop: "32px",
    paddingTop: "32px",
    paddingBottom: "32px",
    borderTop: "1px solid #3d1d0a",
    backgroundColor: "#1A0A00",
  },
  bottomFlex: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  linkSpacing: {
    display: "flex",
    gap: "26px",
  },
  poweredText: {
    fontSize: "16px",
    color: "#FFF0E5",
    fontWeight: "300",
  },
  lastText: {
    fontSize: "14px",
    color: "#FFF0E5",
    fontWeight: "300",
    cursor: "pointer",
  },
};

const Footer = () => {
  const navigate = useNavigate();

  const handleTermsClick = () => {
    navigate("/termscondition");
  };
  const handlePolicyClick = () => {
    navigate("/policy");
  };
  const handleRefundClick = () => {
    navigate("/refund");
  };

  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        <div style={styles.flexWrap}>
          {/* Left section */}
          <div style={styles.section}>
            <h2 style={styles.title}>MyGuruji</h2>
            <p style={{ ...styles.paragraph, marginTop: 18 }}>
              Journey to Your Soul's True
            </p>

            <p style={{ ...styles.paragraph, marginTop: 8 }}>
              Purpose Through Astrology
            </p>
          </div>

          {/* Middle section */}
          <div style={styles.section}>
            <h3 style={styles.companyList}>Company</h3>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              <li style={{ ...styles.paragraph, marginTop: 8 }}>Who we are</li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 14,
                  marginTop: 4,
                }}
              >
                <Link
                  to="/about"
                  style={{ textDecoration: "none", color: "#3d1d0a" }}
                >
                  Our services
                </Link>
                <span style={{ ...styles.hotLabel, marginTop: 4 }}>HOT</span>
              </li>
              <li style={{ ...styles.paragraph, marginTop: 8 }}>
                <Link
                  to="/contact"
                  style={{ textDecoration: "none", color: "#3d1d0a" }}
                >
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
          {/* Right section */}
          {/* <div style={styles.section}>
            <h3 style={styles.companyList}>Subscribe newsletter</h3>
            <p style={{ ...styles.paragraph, marginTop: 10 }}>
              Subscribe our newsletter to get
            </p>
            <p style={{ ...styles.paragraph, marginTop: 8 }}>
              the latest news and updates!
            </p>
            <div style={{ display: "flex", marginTop: 16 }}>
              <input
                type="email"
                placeholder="Enter your email address"
                style={styles.subscribeInput}
              />
              <button style={styles.subscribeButton}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M22 2L11 13M22 2l-7 20-4-9-9-4 20-7z" />
                </svg>
              </button>
            </div>
          </div> */}

          {/* Support section */}
          <div>
            <h3 style={styles.companyList}>Need support?</h3>
            <p>help@domain.com</p>
          </div>
        </div>
      </div>

      {/* Bottom section */}
      <div style={styles.bottomSection}>
        <div style={{ ...styles.container, ...styles.bottomFlex }}>
          <p style={styles.poweredText}>© 2024 MyGuruji</p>
          <div style={styles.linkSpacing}>
            <span onClick={handlePolicyClick} style={styles.lastText}>
              Privacy policy
            </span>
            <span onClick={handleTermsClick} style={styles.lastText}>
              Terms and conditions
            </span>
            <span onClick={handleRefundClick} style={styles.lastText}>
              Refund
            </span>
            {/* <span style={styles.lastText}>Copyright</span> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
