// import React from "react";

// const OutlineText = ({
//   text,
//   fontSize = 60,
//   letterSpacing = 5,
//   strokeWidth = 2,
// }) => {
//   return (
//     <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
//       <defs>
//         <style type="text/css">
//           {`
//             @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
//           `}
//         </style>
//       </defs>
//       <text
//         x="50%"
//         y="50%"
//         fontFamily="Roboto, sans-serif"
//         fontSize={`${fontSize}px`}
//         fontWeight="bold"
//         letterSpacing={`${letterSpacing}px`}
//         textAnchor="middle"
//         dominantBaseline="middle"
//         fill="none"
//         stroke="white"
//         strokeWidth={strokeWidth}
//       >
//         {text}
//       </text>
//     </svg>
//   );
// };

// export default OutlineText;

import React from "react";
import scrollingText from "../../../assets/Images/scrollingText.png";

const OutlineText = () => {
  return (
    <div style={{ width: "100%", position: "relative", bottom: 110 }}>
      <img src={scrollingText} style={{ width: "100%", height: 120 }} />
    </div>
  );
};

export default OutlineText;
