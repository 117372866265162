import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Footer from "../HomePage/Components/Footer";

const styles = {
  container: {
    width: "100%",
    background: "linear-gradient(to bottom, #FFA366, #FF8C42)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    padding: "2rem 1rem",
  },
  content: {
    maxWidth: "64rem",
    width: "100%",
  },
  title: {
    fontSize: "3rem",
    fontWeight: "800",
    textAlign: "center",
    color: "#FFF0E5",
    marginBottom: "2rem",
    lineHeight: "1.2",
  },
  card: {
    background: "rgba(255, 240, 229, 0.9)",
    borderRadius: "1rem",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "2rem",
    marginBottom: "2rem",
    transition: "all 0.3s ease",
  },
  heading: {
    fontSize: "1.875rem",
    fontWeight: "700",
    color: "#FF8C42",
    marginBottom: "1.5rem",
  },
  subheading: {
    fontSize: "1.5rem",
    fontWeight: "600",
    color: "#FF8C42",
    marginTop: "1.5rem",
    marginBottom: "1rem",
  },
  paragraph: {
    fontSize: "1.125rem",
    color: "#FF8C42",
    marginBottom: "1rem",
    lineHeight: "1.7",
  },
  list: {
    listStyleType: "decimal",
    paddingLeft: "2rem",
    marginBottom: "1rem",
  },
  listItem: {
    fontSize: "1.125rem",
    color: "#FF8C42",
    marginBottom: "0.5rem",
  },
};

const RefundPolicy = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <motion.h1
          style={styles.title}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6 }}
        >
          Refund Policy
        </motion.h1>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <p style={styles.paragraph}>
            At MyGuruji, we strive to ensure your satisfaction with our
            services. Here's how our refund policy works:
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <h2 style={styles.heading}>Refunds for Purchased Credits</h2>
          <p style={styles.paragraph}>
            Refunds for purchased Credits can be issued to your account within
            one hour of the transaction if the payment was made through an
            Online/Source account. Unfortunately, other refund requests for
            purchased Credits are not permitted.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <h2 style={styles.heading}>Transfer of Credits</h2>
          <p style={styles.paragraph}>
            Transferring Credits to other users or accounts is not allowed. The
            Company will not facilitate the transfer of your in-app credits to
            any other user or account.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 0.8 }}
        >
          <h2 style={styles.heading}>Refund of Used Credits</h2>
          <p style={styles.paragraph}>
            Astrological consultations are inherently subjective, and results
            may vary among different astrologers. Credits spent are intended to
            compensate Astrologer Partners for their time and expertise, rather
            than guarantee specific outcomes. Therefore, no refunds will be
            issued for any inaccuracies or discrepancies in predictions, advice,
            or remedies provided during consultations.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 1 }}
        >
          <h2 style={styles.heading}>Dissatisfaction with Services</h2>
          <p style={styles.paragraph}>
            If you are dissatisfied with the services, please contact our
            customer care within seven days, detailing your concerns.
          </p>
          <ol style={styles.list}>
            <li style={styles.listItem}>
              <strong>Complaint Verification:</strong> Our customer care
              representatives will review your complaint, access relevant
              information, and may request additional details. We aim to gather
              this information within five business days.
            </li>
            <li style={styles.listItem}>
              <strong>Assessment of Dissatisfaction:</strong> Our team will
              assess whether your dissatisfaction is due to specific issues such
              as call disturbances, language barriers, delays, or irrelevant
              responses.
            </li>
            <li style={styles.listItem}>
              <strong>Refund Eligibility:</strong> If your dissatisfaction stems
              from the aforementioned circumstances, a refund of the
              corresponding Credits will be initiated to your account after
              applicable charges are deducted. Refunds will be credited as
              in-app credits.
            </li>
          </ol>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 1.2 }}
        >
          <h2 style={styles.heading}>Refund Processing Time</h2>
          <p style={styles.paragraph}>
            Once a refund is approved, please note the following timeline:
          </p>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              <strong>Processing Time:</strong> It typically takes 2-3 business
              days for our team to process the refund.
            </li>
            <li style={styles.listItem}>
              <strong>Bank Account Credit:</strong> After processing, it may
              take an additional 3-5 working days for the refunded amount to be
              credited to your bank account.
            </li>
          </ul>
          <p style={styles.paragraph}>
            The total time from refund approval to receiving the amount in your
            bank account may take 5-8 working days. Please be patient during
            this process.
          </p>
        </motion.div>

        <motion.div
          style={styles.card}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={fadeInUp}
          transition={{ duration: 0.6, delay: 1.4 }}
        >
          <p style={styles.paragraph}>
            Please note that all other transactions related to our services are
            final and non-refundable.
          </p>
        </motion.div>
      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
